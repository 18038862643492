/* General Body Styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9; /* Light background for better readability */
    color: #333; /* Text color */
    line-height: 1.6; /* Improved text spacing */
}

/* Contact Container */
.contact {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center-align items horizontally */
    gap: 20px; /* Add space between sections */
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background: #fff; /* White background for the card-like appearance */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    border-radius: 8px; /* Rounded corners */
    animation: fadeIn 1.5s ease-in-out; /* Fade-in animation */
}

/* Joya (Image + Name Section) */
.joya {
    display: flex;
    flex-direction: column; /* Stack image and text */
    align-items: center; /* Center-align content */
    gap: 5px; /* Small gap between image and name */
    border: 1px solid #ddd; /* Border around container */
    padding: 10px;
    border-radius: 5px;
    background: #fefefe; /* Slightly off-white for contrast */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
}

.joya:hover {
    transform: scale(1.05); /* Slight zoom on hover */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.joy {
    border: 1px solid #ddd;
    border-radius: 50%; /* Circular image */
    width: 120px;
    padding: 2px; /* Compact border */
    animation: bounce 2s infinite; /* Bouncing effect */
}

.joyname {
    font-weight: bold;
    font-size: 16px;
    color: #444; /* Subtle text color */
}

/* Front Section */
.front {
    text-align: center;
    font-size: 18px;
    padding: 10px;
    border-left: 3px solid #4caf50; /* Decorative left border */
    background: #f5f5f5; /* Light gray background */
    border-radius: 4px;
    animation: slideIn 1s ease-in-out; /* Sliding animation */
}

.front p {
    margin: 10px 0; /* Spacing between paragraphs */
}

/* Description Section */
.description {
    font-size: 16px;
    line-height: 1.8; /* Improved readability */
    background: #fff8dc; /* Light yellow background */
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    animation: fadeIn 2s ease-in-out; /* Smooth fade-in */
}

.description strong {
    color: #e91e63; /* Highlighted text color */
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

/* Question Class */
.question {
    font-size: 20px;
    color: #4caf50;
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for color and size */
}

.question:hover {
    color: #e91e63;
    transform: scale(1.1); /* Slight zoom effect on hover */
}

/* Welcome Class */
.welcome {
    font-size: 16px;
    color: #333; /* Default color for better contrast */
    transition: font-size 0.3s ease, color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover changes */
}

.welcome:hover {
    font-size: 20px;
    color: orange;
    transform: translateY(-3px); /* Subtle upward movement on hover */
}

/* Optional Animation Keyframes */
@keyframes hoverHighlight {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: rgba(255, 165, 0, 0.1); /* Light orange highlight */
    }
    100% {
        background-color: transparent;
    }
}

.question:hover, .welcome:hover {
    animation: hoverHighlight 0.5s ease; /* Apply highlight animation */
}

/* Email Section */
.email-section {
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.email-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between icon and text */
    color: #4caf50;
    font-weight: bold;
    transition: transform 0.3s ease, color 0.3s ease;
}

.email-link:hover {
    color: #e91e63; /* Change color on hover */
    transform: scale(1.1); /* Slight zoom on hover */
}

.email-icon {
    width: 32px; /* Icon size */
    height: 32px;
    border-radius: 50%; /* Optional for circular styling */
    transition: transform 0.3s ease;
}

.email-link:hover .email-icon {
    transform: rotate(15deg); /* Rotate icon slightly on hover */
}

.email-text {
    font-size: 16px;
}
